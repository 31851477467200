import styled from 'styled-components'

export const LoadersContainer = styled.div.attrs({
  className: 'LoadersContainer',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
`

LoadersContainer.displayName = 'LoadersContainer'
