import { useCallback } from 'react'
import { RouteProps } from 'react-router'

import { LoginLayout } from '@/components/layout'
import { Text } from '@/local/components'
import { useNavigate } from '@/routing'

import { Button } from '@/components/Login/elements/Button'
import { SectionTitle } from '@/components/Login/elements/SectionTitle'
import { Row } from '@/components/Login/elements/Row'

import { useTexts } from './useTexts'

type Props = RouteProps

export function ForgotUsername(_props: Props) {
  const texts = useTexts()
  const navigate = useNavigate<uui.routing.MainSections>(true)

  const handleBackToLogin = useCallback(() => {
    navigate('login')
  }, [navigate])

  return (
    <LoginLayout showLogo>
      <SectionTitle>{texts.title}</SectionTitle>

      <Row>
        <Text size="$m">{texts.description}</Text>
      </Row>

      <Row>
        <Button onClick={handleBackToLogin}>{texts.button}</Button>
      </Row>
    </LoginLayout>
  )
}
