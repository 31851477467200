import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { addInspectPin, removeInspectPin, useFitMapToSelection, useFitMap } from '@/map'
import { MainSelection, resetAllMainSelection, setMainSelection } from '@/atoms'
import { selectMapStyles, setCustomMapStyle } from '@/features/domain/ui'
import { SearchOnMapResult, useIsUnmounted, useSearchOnMap } from '@/hooks'
import { useAppDispatch } from '@/store'

export function getSelectionFromAsset(
  asset: uui.domain.client.gps.SearchOnMapItem,
): { category: keyof MainSelection; id: string } | undefined {
  const { type } = asset

  if (type === 'depot') {
    return {
      category: 'depots',
      id: asset.id,
    }
  }

  if (type === 'gpsPlace') {
    return {
      category: 'places',
      id: asset.id,
    }
  }
}

export function useSearchOnMapActions(
  closeSearchOnMap: () => void,
  setLocation: (location: uui.domain.client.Location) => void,
  setIsLoading: (isLoading: boolean) => void,
  setAssetList: (items: uui.domain.client.gps.SearchOnMapItem[]) => void,
) {
  const fitMapToSelection = useFitMapToSelection()
  const mapStyles = useSelector(selectMapStyles)
  const dispatch = useAppDispatch()
  const fitMap = useFitMap()
  const isUnmounted = useIsUnmounted()

  const searchOnMap = useSearchOnMap({ assetTypes: ['depot', 'place'] })

  const searchOnMapTimeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined)
  useEffect(
    () => () => {
      if (searchOnMapTimeoutIdRef.current) {
        clearTimeout(searchOnMapTimeoutIdRef.current)
      }
    },
    [],
  )

  const onLocationChange = useCallback(
    async (asset: uui.domain.client.gps.SearchOnMapItem, location: uui.domain.client.Location) => {
      if (
        asset.type !== 'address' &&
        asset.type !== 'place' &&
        asset.type !== 'gpsPlace' &&
        asset.type !== 'depot'
      )
        return

      // necessary conversion for map styles
      const assetType = asset.type === 'gpsPlace' ? 'place' : asset.type

      setLocation(location)
      const selection = getSelectionFromAsset(asset)

      if (selection) {
        // Update the main selection
        setMainSelection(selection.category, [selection.id])

        // Update the map style if is `off`
        const assetMapStyle =
          mapStyles[assetType]?.custom[selection.id] || mapStyles[assetType].mode

        if (assetMapStyle === 'off' && assetType !== 'address') {
          await dispatch(
            setCustomMapStyle({
              customStyle: { type: assetType, mode: 'on', ids: [selection.id] },
            }),
          )
        }

        fitMapToSelection(true)

        // Remove the inspect pin
        removeInspectPin()

        closeSearchOnMap()
      } else {
        const latLng =
          asset.type === 'place'
            ? asset.place.latLng
            : asset.type === 'gpsPlace'
            ? asset.latLng
            : asset.location.latLng

        resetAllMainSelection()
        addInspectPin(latLng)
        fitMap([latLng])
      }
    },
    [dispatch, fitMapToSelection, closeSearchOnMap, setLocation, fitMap, mapStyles],
  )

  const onClose = useCallback(() => {
    setIsLoading(false)
    closeSearchOnMap()
  }, [closeSearchOnMap, setIsLoading])

  const onTextChange = useCallback(
    (address: string, _relatedLocation: uui.domain.client.Location) => {
      setIsLoading(true)

      // searchOnMap return a timeout ID, we clear it when the component unmounts
      searchOnMapTimeoutIdRef.current = searchOnMap(address, (result: SearchOnMapResult) => {
        if (isUnmounted()) return

        setAssetList(result.items)

        if (result.requestStatus !== 'pending') {
          setIsLoading(false)
        }
      })
    },
    [setAssetList, searchOnMap, setIsLoading, isUnmounted],
  )

  return { onClose, onTextChange, onLocationChange }
}
