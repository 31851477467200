import type { MouseEvent } from 'react'
import type { GridApi } from '@mui/x-data-grid-pro'

import { useCallback, useState, useMemo, useRef } from 'react'
import { gridColumnDefinitionsSelector } from '@mui/x-data-grid-pro'

export function useColumnsPanel(
  apiRef: React.MutableRefObject<GridApi>,
  lockedColumns: string[],
  defaultColumnsPanelSortBy: 'category' | 'grid-order',
  onToggleAllColumns?: (visible: boolean) => void,
) {
  const columns = gridColumnDefinitionsSelector(apiRef)
  const [searchValue, setSearchValue] = useState('')
  const [sortMethod, setSortMethod] = useState<'category' | 'grid-order'>(defaultColumnsPanelSortBy)
  const originalColumnsRef = useRef(columns)
  const defaultColumnsPanelSortByRef = useRef(defaultColumnsPanelSortBy)

  const toggleColumn = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!apiRef) return

      const { name } = event.target as HTMLInputElement
      const column = apiRef.current.getColumn(name)
      apiRef.current.setColumnVisibility(name, !!column.hide)
    },
    [apiRef],
  )

  const toggleAllColumns = useCallback(
    (value: boolean) => {
      if (!apiRef) return

      apiRef.current.updateColumns(
        columns.map(col => {
          if (lockedColumns.includes(col.field)) return col

          return { ...col, hide: value }
        }),
      )

      onToggleAllColumns?.(value)
    },
    [apiRef, columns, lockedColumns, onToggleAllColumns],
  )

  const resetColumns = useCallback(() => {
    if (!apiRef) return

    apiRef.current.updateColumns(originalColumnsRef.current)
    setSortMethod(defaultColumnsPanelSortByRef.current)
  }, [apiRef])

  const showAllColumns = useCallback(() => toggleAllColumns(false), [toggleAllColumns])
  const hideAllColumns = useCallback(() => toggleAllColumns(true), [toggleAllColumns])

  const handleSearchValueChange = useCallback(event => {
    setSearchValue(event.target.value)
  }, [])

  const currentColumns = useMemo(() => {
    // Filter columns by search term
    const filteredColumns = searchValue
      ? columns.filter(
          column =>
            column.field.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
            (column.headerName &&
              column.headerName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1),
        )
      : columns

    // Remove locked columns from the list
    return filteredColumns.filter(c => !lockedColumns.includes(c.field))
  }, [columns, searchValue, lockedColumns])

  return {
    actions: {
      resetColumns,
      toggleColumn,
      setSortMethod,
      showAllColumns,
      hideAllColumns,
      handleSearchValueChange,
    },
    data: {
      sortMethod,
      searchValue,
      currentColumns,
    },
  }
}
