import { VerticalLayout } from '@/components/layout'
import SmallLogo from '@/resources/ww-stacked-logo-white.svg'

import FooterLink from './elements/FooterLink'
import FooterContainer from './elements/FooterContainer'

export function Footer() {
  return (
    <FooterContainer>
      <VerticalLayout alignItems="center">
        <img width={95} src={SmallLogo} />
        <p>101 Crawfords Corner Road, Suite 2511-W, Holmdel, NJ 07733</p>
        <FooterLink>
          <a href="https://www.workwave.com/" target="_blank" rel="noopener noreferrer">
            Copyright 2017, WorkWave LLC. All rights reserved
          </a>
          <a href="https://www.workwave.com/terms-of-use" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
          <a
            href="https://www.workwave.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.workwave.com/end-of-life-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            End of Life Policy
          </a>
        </FooterLink>
      </VerticalLayout>
    </FooterContainer>
  )
}
