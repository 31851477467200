import styled from 'styled-components'

const styledComponent = styled.div`
  width: 100%;
  font-size: ${p => p.theme.fonts.$m};
  font-weight: ${p => p.theme.weights.$light};
  color: ${p => p.theme.colors.$pureWhite};
  padding-bottom: 20px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`

styledComponent.displayName = 'LoginFooterContainer'
export default styledComponent
