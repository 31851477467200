import styled from 'styled-components'
import { applyAlphaToColor } from '@/local/components'

const FORM_WIDTH = 270
const INPUT_HEIGHT = 43
const INPUT_FONT_SIZE = 18
const INPUT_ICON_SIZE = 22

export const Body = styled.div`
  width: ${FORM_WIDTH}px;
  color: ${p => p.theme.colors.$pureWhite};
  margin: 0;
  padding-top: 20px;

  input::placeholder {
    color: ${p => p.theme.colors.$pureWhite};
  }

  > * {
    padding: 20px 0;
  }

  .o-input-field-text__extra-icon {
    width: ${INPUT_ICON_SIZE}px;
    color: ${p => p.theme.colors.$pureWhite};
    margin-right: ${INPUT_ICON_SIZE / 2}px;
  }

  .o-input-field-text {
    height: ${INPUT_HEIGHT}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: ${p => applyAlphaToColor(p.theme.colors.$whiteSmoke, 0.25)};
    border: 0;
    font-size: ${p => p.theme.fonts.$p3};
  }

  .o-input-field-text__base-input {
    color: ${p => p.theme.colors.$pureWhite};
    font-weight: normal;
    font-size: ${INPUT_FONT_SIZE}px;
  }

  .o-button {
    margin-top: 5px;
  }
`

Body.displayName = 'LoginBody'
