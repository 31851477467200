import { styled } from '@mui/material'

export const Container = styled('div', {
  name: 'Container',
  shouldForwardProp: prop => prop !== 'gridHeight',
})<{ gridHeight?: number }>(({ gridHeight, theme }) => {
  return {
    padding: `${theme.spacing(1)} 0px ${theme.spacing(1)} ${theme.spacing(1)}`,
    maxHeight: !gridHeight || gridHeight < 130 ? 0 : gridHeight - 130,
  }
})
