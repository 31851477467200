import { Switch, FormControlLabel } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid-pro'
import { Column } from './Column'

const switchSx = { marginRight: 4 }

interface Props {
  data: { currentColumns: GridColDef<any, any, any>[] }
  actions: {
    toggleColumn: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

export function ColumnsByGridSort(props: Props) {
  const { data, actions } = props

  return data.currentColumns.map(column => (
    <Column key={column.field}>
      <FormControlLabel
        data-testid="orders-grid-column-switch"
        data-trackid="orders-grid-column-switch"
        control={
          <Switch
            onClick={actions.toggleColumn}
            checked={!column.hide}
            name={column.field}
            color="primary"
            sx={switchSx}
            size="small"
          />
        }
        label={column.headerName || column.field}
      />
    </Column>
  ))
}
