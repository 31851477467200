import styled from 'styled-components'

type Props = {
  flexDirection?: 'column' | 'row'
}

export const CenteredLayout = styled.div<Props>`
  display: flex;
  flex-direction: ${p => p.flexDirection ?? 'row'};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

CenteredLayout.displayName = 'CenteredLayout'
