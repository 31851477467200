import { useRef, useEffect, useState, useCallback } from 'react'

import {
  Stack,
  Paper,
  Button,
  Popper,
  MenuList,
  MenuItem,
  TextField,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
} from '@mui/material'
import { Check } from '@mui/icons-material'
import {
  GridPanelHeader,
  GridPanelFooter,
  GridPanelWrapper,
  GridPanelContent,
  useGridApiContext,
} from '@mui/x-data-grid-pro'

import { useOrdersGrid } from '@/atoms'

import { ColumnsByCategory } from './components/ColumnsByCategory'
import { ColumnsByGridSort } from './components/ColumnsByGridSort'
import { useColumnsPanel } from './hooks/useColumnsPanel'
import { Container } from './components/Container'
import { useTexts } from './hooks/useTexts'

export interface ColumnsPanelProps {
  onToggleAllColumns?: (visible: boolean) => void

  lockedColumns?: string[]
}

export function ColumnsPanel(props: ColumnsPanelProps) {
  const { lockedColumns = [], onToggleAllColumns } = props
  const [
    {
      settings: { columnsPanelSortBy },
    },
    setOrdersGridAtom,
  ] = useOrdersGrid()

  const [sortByMenuAnchorEl, setSortByMenuAnchorEl] = useState<null | HTMLElement>(null)

  const searchInputRef = useRef<HTMLInputElement>(null)
  const apiRef = useGridApiContext()

  const { data, actions } = useColumnsPanel(
    apiRef,
    lockedColumns,
    columnsPanelSortBy,
    onToggleAllColumns,
  )
  const texts = useTexts()

  const hideSortByMenu = useCallback(() => setSortByMenuAnchorEl(null), [])

  const showSortByMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSortByMenuAnchorEl(event.currentTarget)
  }

  const setColumnsSortByGridOrder = useCallback(() => {
    actions.setSortMethod('grid-order')
  }, [actions])

  const setColumnsSortByCategory = useCallback(() => {
    actions.setSortMethod('category')
  }, [actions])

  useEffect(() => {
    setOrdersGridAtom(state => ({
      ...state,
      columnsPanelSortBy: data.sortMethod,
    }))
  }, [data.sortMethod, setOrdersGridAtom])

  useEffect(() => {
    searchInputRef.current!.focus()
  }, [])

  const allColumnsVisible = apiRef.current?.getAllColumns().every(column => !column.hide)

  const open = Boolean(sortByMenuAnchorEl)

  if (!apiRef) return null

  return (
    <GridPanelWrapper
      data-testid="orders-grid-columns-panel"
      data-trackid="orders-grid-columns-panel"
    >
      <GridPanelHeader>
        <Stack spacing={1}>
          <TextField
            placeholder={apiRef.current.getLocaleText('columnsPanelTextFieldPlaceholder')}
            label={apiRef.current.getLocaleText('columnsPanelTextFieldLabel')}
            onChange={actions.handleSearchValueChange}
            inputRef={searchInputRef}
            value={data.searchValue}
            data-testid="orders-grid-search-input"
            data-trackid="orders-grid-search-input"
            variant="standard"
            fullWidth
          />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            {allColumnsVisible ? (
              <Button
                onClick={actions.hideAllColumns}
                color="primary"
                data-testid="orders-grid-columns-panel-hide-button"
                data-trackid="orders-grid-columns-panel-hide-button"
              >
                {apiRef.current.getLocaleText('columnsPanelHideAllButton')}
              </Button>
            ) : (
              <Button
                onClick={actions.showAllColumns}
                color="primary"
                data-testid="orders-grid-columns-panel-show-button"
                data-trackid="orders-grid-columns-panel-show-button"
              >
                {apiRef.current.getLocaleText('columnsPanelShowAllButton')}
              </Button>
            )}
            <Button
              color="primary"
              onClick={showSortByMenu}
              data-testid="orders-grid-columns-panel-show-button"
              data-trackid="orders-grid-columns-panel-show-button"
            >
              {texts.sortBy}
            </Button>
            <Popper open={open} anchorEl={sortByMenuAnchorEl} disablePortal placement="right-end">
              <Paper elevation={3}>
                <ClickAwayListener onClickAway={hideSortByMenu}>
                  <MenuList>
                    <MenuItem onClick={setColumnsSortByCategory}>
                      <ListItemIcon sx={{ opacity: data.sortMethod === 'category' ? 1 : 0 }}>
                        <Check fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>{texts.sortByCategory}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={setColumnsSortByGridOrder}>
                      <ListItemIcon sx={{ opacity: data.sortMethod === 'grid-order' ? 1 : 0 }}>
                        <Check fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>{texts.sortByGridOrder}</ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Stack>
        </Stack>
      </GridPanelHeader>
      <GridPanelContent>
        <Container gridHeight={apiRef?.current?.windowRef?.current?.offsetHeight}>
          {data.sortMethod === 'grid-order' ? (
            <ColumnsByGridSort data={data} actions={actions} />
          ) : (
            <ColumnsByCategory data={data} actions={actions} />
          )}
        </Container>
      </GridPanelContent>
      <GridPanelFooter>
        <Button
          onClick={actions.resetColumns}
          color="primary"
          data-testid="orders-grid-columns-panel-reset-button"
          data-trackid="orders-grid-columns-panel-reset-button"
        >
          {texts.reset}
        </Button>

        <Button
          onClick={() => apiRef?.current?.hidePreferences()}
          color="primary"
          data-testid="orders-grid-columns-panel-close-button"
          data-trackid="orders-grid-columns-panel-close-button"
        >
          {texts.done}
        </Button>
      </GridPanelFooter>
    </GridPanelWrapper>
  )
}
