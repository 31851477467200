import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { unwrapResult } from '@reduxjs/toolkit'

import { useAppDispatch } from '@/store'
import { useIsUnmounted } from '@/hooks'
import { loadOrderStepNotifications } from '@/features/domain/order'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectCalendarRange } from '@/features/domain/ui'

import { OrdStepId } from '../typings'
import { getOrdStepId } from '../utils'

import {
  setOrderStepNotification,
  hasOrderStepNotification,
} from '../../../../../orderNotificationAtom'

type LoadingStatus = 'loading' | 'failed' | 'ready'

const refreshRate = 30 * 1000

export const useOrderStepNotificationStatus = (
  extendedOrder: uui.domain.client.rm.ExtendedOrderStep,
) => {
  const {
    orderStep: { id: orderStepId },
  } = extendedOrder

  const { planType } = useSelector(selectUserConfiguration)
  const calendarRange = useSelector(selectCalendarRange)
  const dispatch = useAppDispatch()
  const isUnmounted = useIsUnmounted()

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(() => 'loading')

  const waitingForServer = useRef(false)
  const ordStepId = useMemo(() => getOrdStepId(extendedOrder), [extendedOrder])
  const intervalId = useRef<NodeJS.Timeout | null>(null)

  const archivedDate = planType === 'archived' ? calendarRange.minDate : undefined

  const fetchData = useCallback(
    async (ordStepId: OrdStepId, orderStepId: string, archivedDate?: string) => {
      waitingForServer.current = true

      const notificationsRequest = await dispatch(
        loadOrderStepNotifications({
          ordStepId,
          archivedDate,
        }),
      )

      let hasError = false

      if (loadOrderStepNotifications.fulfilled.match(notificationsRequest)) {
        const orderStepNotifications = unwrapResult(notificationsRequest)

        setOrderStepNotification({
          orderStepNotifications,
          requestTs: new Date().getTime(),
          id: orderStepId,
        })

        waitingForServer.current = false
      } else {
        hasError = true
      }

      if (!isUnmounted()) {
        setLoadingStatus(hasError ? 'failed' : 'ready')
      }
    },
    [dispatch, isUnmounted],
  )

  const autoFetchData = useCallback(() => {
    if (waitingForServer.current || isUnmounted()) return

    fetchData(ordStepId, orderStepId, archivedDate)
  }, [ordStepId, orderStepId, fetchData, archivedDate, isUnmounted])

  useEffect(() => {
    if (hasOrderStepNotification(orderStepId)) {
      setLoadingStatus('ready')
    } else {
      setLoadingStatus('loading')
      fetchData(ordStepId, orderStepId, archivedDate)
    }
  }, [orderStepId, ordStepId, fetchData, archivedDate])

  useEffect(() => {
    intervalId.current = setInterval(autoFetchData, refreshRate)

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current)
      }
    }
  }, [autoFetchData])

  return loadingStatus
}
