import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState({
    title: translate({ id: 'login.form.forgotUsername.title' }),
    description: translate({ id: 'login.form.forgotUsername.description' }),
    button: translate({ id: 'login.loginPage' }),
  })

  return api
}
