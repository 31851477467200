import styled from 'styled-components'

export const ModalsContainer = styled.div.attrs({
  className: 'ModalsContainer',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
`

ModalsContainer.displayName = 'ModalsContainer'
