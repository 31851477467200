import { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'

import { useDidUpdate } from '@/local/hooks'

import { copyToClipboard } from '@/utils'

import renderText from '../intl'
import TextButton from '../elements/TextButton'

import { Props, LocationEditorText } from '../typings'

const AdditionalModes = (props: Props) => {
  const { modes = [], onCopyCoordinates, location, status } = props
  const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(status === 'invalidLatLng')

  const timer = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])

  const hasCopyCoordinates: boolean = modes.includes('copyCoordinates')

  const handleOnCopyCoordinates = useCallback(() => {
    if (onCopyCoordinates) {
      onCopyCoordinates(location)
    }
    copyToClipboard(location.rawLatLngAsString)
    setShowCopiedMessage(true)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setShowCopiedMessage(false)
    }, 1000)
  }, [location, onCopyCoordinates])

  useDidUpdate(() => {
    setDisabled(status === 'invalidLatLng')
  }, [status])

  const copyMessageId: LocationEditorText = showCopiedMessage
    ? LocationEditorText.copied
    : LocationEditorText.copyCoordinates

  if (!hasCopyCoordinates) return null

  return (
    <TextButton
      disabled={disabled}
      onClick={handleOnCopyCoordinates}
      data-testid="location-editor__copy-coordinates"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <ContentCopy fontSize="small" />
        <Box>{renderText(copyMessageId)}</Box>
      </Stack>
    </TextButton>
  )
}

export default AdditionalModes
