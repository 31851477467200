import styled from 'styled-components'

export const SectionTitle = styled.div`
  color: ${p => p.theme.colors.$whiteSmoke};
  font-size: ${p => p.theme.fonts.$h1};
  display: flex;
  align-items: center;
  justify-content: center;
`

SectionTitle.displayName = 'LoginSectionTitle'
