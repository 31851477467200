import styled from 'styled-components'

import { Button as CompButton } from '@/forms-legacy'
import { applyAlphaToColor } from '@/local/components'

const BUTTON_TEXT_SIZE = 20

export const Button = styled(CompButton).attrs({ type: 'submit' })`
  color: ${p => p.theme.colors.$pureBlack};
  width: 100%;
  background: ${p => applyAlphaToColor(p.theme.colors.$pureWhite, 0.25)};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  &:hover,
  &:hover:not(:disabled) {
    background: ${p => applyAlphaToColor(p.theme.colors.$pureWhite, 0.25)};
  }

  .o-button__inner {
    color: ${p => p.theme.colors.$pureWhite};
    padding: 0;
    font-size: ${BUTTON_TEXT_SIZE}px;
    z-index: 10;
  }
`

Button.displayName = 'LoginButton'
