import { type ChangeEventHandler, useCallback, useEffect, useState, useMemo } from 'react'
import {
  ClickAwayListener,
  FormControlLabel,
  TextField,
  Button,
  Switch,
  Paper,
  Stack,
} from '@mui/material'
import { type Column, type ColumnStore, type SchedulerPro } from '@bryntum/schedulerpro'

import { getInitialSchedulerColumn, setSchedulerColumns } from '../../../../atoms/columns'
import { getColumnsFromConfig } from '../../../../utils/createSchedulerConfig/columnStore/utils/getColumnsFromConfig'
import { useTexts } from './useTexts'

type Props = {
  scheduler?: SchedulerPro
  close: () => void
  x: number
  y: number
}

export function ColumnsManager(props: Props) {
  const { scheduler, close, x, y } = props

  const texts = useTexts()
  const [filter, setFilter] = useState<string>('')
  const [columns, setColumns] = useState<Record<string, boolean>>(() => {
    if (!scheduler) return {}

    const columnStore = scheduler.columns as ColumnStore

    if (!columnStore) return {}

    return columnStore.allRecords.reduce((acc, column) => {
      const text = column.getData('text')
      if (!text) {
        return acc
      }

      if (column.getData('region') === 'cols') {
        acc[column.id] = !column.getData('hidden')
      }

      return acc
    }, {})
  })

  const onFilterInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setFilter(e.target.value)
  }, [])

  const showAll = useCallback(() => {
    if (!scheduler) return
    const columnStore = scheduler.columns as ColumnStore

    if (!columnStore) return

    for (const column of columnStore.allRecords as Column[]) {
      column.show()
    }
  }, [scheduler])

  const hideAll = useCallback(() => {
    if (!scheduler) return
    const columnStore = scheduler.columns as ColumnStore

    if (!columnStore) return

    for (const column of columnStore.allRecords as Column[]) {
      if (column.getData('region') !== 'cols') continue

      column.hide()
    }
  }, [scheduler])

  const resetDefaultColumns = useCallback(() => {
    if (!scheduler) return
    const columnStore = scheduler.columns as ColumnStore

    if (!columnStore) return

    columnStore.removeAll()

    setSchedulerColumns('reset')

    const defaultColumns = getColumnsFromConfig(getInitialSchedulerColumn())
    columnStore.add(defaultColumns)
  }, [scheduler])

  const allColumnsVisible = useMemo(() => {
    return !Object.values(columns).some(value => value === false)
  }, [columns])

  useEffect(() => {
    if (!scheduler) return
    const columnStore = scheduler.columns as ColumnStore

    if (!columnStore) return

    columnStore.onChange = () => {
      const managedColumns = columnStore.allRecords.reduce((acc, column) => {
        const text = column.getData('text')
        if (!text) return acc

        if (column.getData('region') === 'cols') {
          acc[column.id] = !column.getData('hidden')
        }

        return acc
      }, {})

      setColumns(managedColumns)
    }
  }, [scheduler])

  if (!scheduler) return null

  const negativeOffset =
    !isNaN(scheduler.height as number) && (scheduler.height as number) < 200 ? 60 : 0

  const testIdMap = {
    root: 'columns-manager-root',
    searchInput: 'columns-manager-search-input',
    list: 'columns-manager-list',
  }

  return (
    <ClickAwayListener onClickAway={close}>
      <Stack
        data-testid={testIdMap.root}
        data-trackid={testIdMap.root}
        position="fixed"
        width={395}
        left={x}
        top={y - negativeOffset}
        maxHeight="100%"
      >
        <Paper elevation={3}>
          <Stack padding={1} spacing={1} alignItems="flex-start">
            <TextField
              data-testid={testIdMap.searchInput}
              data-trackid={testIdMap.searchInput}
              variant="standard"
              label="Filter"
              onChange={onFilterInputChange}
              autoFocus
            />

            <Button onClick={allColumnsVisible ? hideAll : showAll}>
              {allColumnsVisible ? texts.hideAll : texts.showAll}
            </Button>

            <Stack
              data-testid={testIdMap.list}
              data-trackid={testIdMap.list}
              maxHeight={`calc(${scheduler.height}px - 180px)`}
              minHeight={20}
              spacing={1}
              width="100%"
              overflow="auto"
              flex="1 1"
            >
              {Object.entries(columns).map(([columnId, visible], index) => {
                const column = (scheduler.columns as ColumnStore).getById(columnId)

                if (!column) return null

                const label = column.getData('text')
                const invalidColumn =
                  !label || (filter !== '' && !label.toLowerCase().includes(filter.toLowerCase()))

                if (invalidColumn) return null

                return (
                  <FormControlLabel
                    control={<Switch size="small" checked={visible} />}
                    onChange={(_e, checked) => {
                      if (checked) {
                        column.show()
                      } else {
                        column.hide()
                      }
                    }}
                    label={label}
                    key={index}
                  />
                )
              })}
            </Stack>

            <Stack direction="row" width="100%" justifyContent="space-between">
              <Button onClick={resetDefaultColumns}>{texts.reset}</Button>
              <Button onClick={close}>{texts.done}</Button>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </ClickAwayListener>
  )
}
