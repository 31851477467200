export const guards = {
  uui: {
    routing: {
      /**
       * Check if the route belongs to the error section.
       * ATTENTION: every nested path should be unique.
       */
      Error: (route: string): route is uui.routing.Error => {
        const r = route as uui.routing.Error
        return (
          r === '404' ||
          r === '500' ||
          r === 'unsupported' ||
          r === 'generic-error' ||
          r === 'invalid-reset-password-token'
        )
      },

      /**
       * Check if the route is a top-level one.
       * ATTENTION: every nested path should be unique.
       */
      MainSections: (route: string): route is uui.routing.MainSections => {
        const r = route as uui.routing.MainSections
        return (
          r === '/' ||
          r === 'login' ||
          r === 'setup' ||
          r === 'error' ||
          r === 'events' ||
          r === 'routing' ||
          r === 'tracking' ||
          r === 'settings' ||
          r === 'import-orders' ||
          r === 'forgot-password' ||
          r === 'forgot-username' ||
          r === 'resetpassword'
        )
      },

      /**
       * Check if the route belongs to the Routing section.
       * ATTENTION: every nested path should be unique.
       */
      Routing: (route: string): route is uui.routing.Routing => {
        const r = route as uui.routing.Routing
        return r === '*' || r === 'home' || r === 'orders' || r === 'simulations'
      },

      /**
       * Check if the route belongs to the settings section.
       * ATTENTION: every nested path should be unique.
       */
      Settings: (route: string): route is uui.routing.Settings => {
        const r = route as uui.routing.Settings
        return (
          r === 'account' ||
          r === 'integrations' ||
          r === 'user-management' ||
          r === 'personalization' ||
          r === 'communication-plan' ||
          r === 'language-and-formats'
        )
      },

      /**
       * Check if the route belongs to the setup section.
       * ATTENTION: every nested path should be unique.
       */
      Setup: (route: string): route is uui.routing.Setup => {
        const r = route as uui.routing.Setup
        return (
          r === '*' ||
          r === 'roads' ||
          r === 'depots' ||
          r === 'drivers' ||
          r === 'regions' ||
          r === 'vehicles' ||
          r === 'traffic-area' ||
          r === 'driver-assignments' ||
          // GPS
          r === 'places' ||
          r === 'alerts' ||
          r === 'devices' ||
          r === 'geofences' ||
          // Territory settings: notifications
          r === 'voice' ||
          r === 'preferences' ||
          r === 'tracking-app' ||
          r === 'notifications' ||
          // Driver mobile App
          r === 'driver-activity' ||
          r === 'driver-assistance' ||
          // Territory
          r === 'territory'
        )
      },
    },
  },
}
