import styled from 'styled-components'

export const AppFooter = styled.footer.attrs({
  className: 'AppFooter',
})`
  display: block;
  flex: 0 0 auto;
`

AppFooter.displayName = 'AppFooter'
