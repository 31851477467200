import type { ReactNode } from 'react'

import { VerticalLayout, CenteredLayout } from '@/components/layout'

import Logo from '@/resources/ww-route-manager-white.svg'

import { LoginPageBackground } from './elements/LoginPageBackground'
import { Footer } from './elements/Footer'
import { Body } from './elements/Body'

type Props = {
  showLogo?: boolean
  children?: ReactNode
}

export function LoginLayout(props: Props) {
  const { children, showLogo } = props

  return (
    <>
      <LoginPageBackground />

      <VerticalLayout>
        <CenteredLayout flexDirection="column">
          {showLogo && <img width={265} src={Logo} />}
          <Body>{children}</Body>
        </CenteredLayout>
        <Footer />
      </VerticalLayout>
    </>
  )
}
