import styled from 'styled-components'
import loginBackground from '@/resources/login-background.jpg'

export const LoginPageBackground = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url(${loginBackground});
`

LoginPageBackground.displayName = 'LoginPageBackground'
