import { useRef, useEffect, useCallback, ChangeEvent, useState } from 'react'

type OnChange = (value: string) => void

const emptyTimeout = setTimeout(() => undefined, 0)

type UseDebounceText = [value: string, onChange: (event: ChangeEvent<HTMLInputElement>) => void]

export function useDebounceText(
  value: string,
  onChange: OnChange,
  delay?: number,
): UseDebounceText {
  const rTimer = useRef<NodeJS.Timer>(emptyTimeout)

  const [localValue, setLocalValue] = useState(value)
  const rLocalValue = useRef(localValue)

  useEffect(() => {
    setLocalValue(value)
    rLocalValue.current = value
    return () => clearTimeout(rTimer.current)
  }, [value])

  const debounceOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(rTimer.current)
      if (!delay) return
      const value = event.currentTarget.value
      setLocalValue(value)
      rLocalValue.current = value
      rTimer.current = globalThis.setTimeout(() => onChange(rLocalValue.current), delay)
    },
    [onChange, delay],
  )

  const noDebounceOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.currentTarget.value)
    },
    [onChange],
  )

  return delay === undefined ? [value, noDebounceOnChange] : [localValue, debounceOnChange]
}
