import styled from 'styled-components'

const styledComponent = styled.div`
  & > a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`

styledComponent.displayName = 'LoginFooterLink'
export default styledComponent
