import styled from 'styled-components'

export const AppBody = styled.div`
  display: block;
  flex: 0 1 auto;
  height: 100%;
  overflow: hidden;
`

AppBody.displayName = 'AppBody'
