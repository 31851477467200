import { useSelector } from 'react-redux'
import { StoreMallDirectory, Extension } from '@mui/icons-material'

import { User, Numbers, Wrench, ContactCard, Users } from '@/icons'
import { selectUserConfiguration, selectUserType } from '@/features/domain/user'
import { selectNotificationPlanDetails } from '@/features/domain/territory'
import { selectTelematicsSources } from '@/features/domain/account'
import { useGotIntegrationErrors } from '@/hooks'
import { ActionButton } from '@/components/primitives/buttons/ActionButton'
import { Tooltip } from '@/components/primitives/Tooltip'
import { Link } from '@/routing'

import { useTexts } from '../useTexts'

import { Separator } from './Separator'

type SettingsRoutes = uui.routing.Settings

export function RmSidebar() {
  const gotIntegrationsErrors = useGotIntegrationErrors()
  const telematicsSources = useSelector(selectTelematicsSources)
  const planDetails = useSelector(selectNotificationPlanDetails)
  const userConfig = useSelector(selectUserConfiguration)
  const userType = useSelector(selectUserType)
  const texts = useTexts()

  const isAdminUser = userType === 'admin'
  const isRmOnly = userConfig.userType === 'rmOnly'

  const canSeeIntegrations =
    (userType === 'admin' || userType === 'planner') && Object.keys(telematicsSources).length > 0

  const communicationDisabled = isRmOnly || !planDetails

  return (
    <>
      <Link<SettingsRoutes> to="account">
        <ActionButton
          text={texts.account}
          Icon={<User size={18} />}
          testid="settings__account-button"
        />
      </Link>

      <Link<SettingsRoutes> to="language-and-formats">
        <ActionButton
          text={texts.languageAndFormats}
          Icon={<Numbers size={18} />}
          testid="settings__language-and-formats-button"
        />
      </Link>

      <Link<SettingsRoutes> to="personalization">
        <ActionButton
          text={texts.personalization}
          Icon={<Wrench size={18} />}
          testid="settings__personalization-button"
        />
      </Link>

      {isAdminUser && (
        <>
          <Separator />

          <Link<SettingsRoutes> to="user-management">
            <ActionButton
              text={texts.userManagement}
              Icon={<Users size={18} />}
              testid="settings__user-management-button"
            />
          </Link>

          <Link<SettingsRoutes> to="companies">
            <ActionButton
              text={texts.companies}
              Icon={<StoreMallDirectory width={18} />}
              testid="settings__companies-button"
            />
          </Link>

          <Link<SettingsRoutes> to="communication-plan" disabled={communicationDisabled}>
            <Tooltip
              placement="top"
              title={texts.communicationPlanTooltip(isRmOnly)}
              disabled={!communicationDisabled}
            >
              <ActionButton
                disabled={communicationDisabled}
                text={texts.communicationPlan}
                Icon={<ContactCard size={18} />}
                testid="settings__communication-plan-button"
              />
            </Tooltip>
          </Link>
        </>
      )}

      {canSeeIntegrations && (
        <Link<SettingsRoutes> to="integrations">
          <ActionButton
            error={gotIntegrationsErrors}
            text={texts.integrations}
            Icon={<Extension fontSize="small" />}
            testid="settings__integrations-button"
          />
        </Link>
      )}
    </>
  )
}
