import { useMemo } from 'react'
import { Switch, FormControlLabel, Stack, Typography } from '@mui/material'
import { type GridColDef } from '@mui/x-data-grid-pro'
import { naturalSort } from '@/server-data'

import { useTexts } from '../hooks/useTexts'
import { Column } from './Column'

type CollectedColumns = {
  notifications: GridColDef<any, any, any>[]
  customFields: GridColDef<any, any, any>[]
  generic: GridColDef<any, any, any>[]
  loads: GridColDef<any, any, any>[]
}

const switchSx = { marginRight: 4 }
function getDefaultCollectedColumns(): CollectedColumns {
  return {
    notifications: [],
    customFields: [],
    generic: [],
    loads: [],
  }
}

interface Props {
  data: { currentColumns: GridColDef<any, any, any>[] }
  actions: {
    toggleColumn: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

export function ColumnsByCategory(props: Props) {
  const { data, actions } = props
  const texts = useTexts()
  const categorizedColumns = useMemo(() => {
    const collectedColumns = data.currentColumns.reduce<CollectedColumns>((acc, column) => {
      const { field } = column
      if (
        field.startsWith('formattedData.loads') ||
        field.startsWith('formattedData.executedLoads')
      ) {
        acc.loads.push(column)
        return acc
      }

      if (
        field.startsWith('formattedData.customFields') ||
        field.startsWith('formattedData.executedCustomFields')
      ) {
        acc.customFields.push(column)
        return acc
      }

      if (field.startsWith('formattedData.communicatedEtaRanges')) {
        acc.notifications.push(column)
        return acc
      }

      acc.generic.push(column)
      return acc
    }, getDefaultCollectedColumns())

    collectedColumns.customFields = collectedColumns.customFields.sort((a, b) =>
      naturalSort(a.headerName ?? '', b.headerName ?? ''),
    )

    collectedColumns.generic = collectedColumns.generic.sort((a, b) =>
      naturalSort(a.headerName ?? '', b.headerName ?? ''),
    )

    collectedColumns.loads = collectedColumns.loads.sort((a, b) =>
      naturalSort(a.headerName ?? '', b.headerName ?? ''),
    )

    collectedColumns.notifications = collectedColumns.notifications.sort((a, b) =>
      naturalSort(a.headerName ?? '', b.headerName ?? ''),
    )

    return collectedColumns
  }, [data.currentColumns])

  return (
    <Stack>
      {categorizedColumns.generic.map(column => (
        <Column key={column.field}>
          <FormControlLabel
            data-testid="orders-grid-column-switch"
            data-trackid="orders-grid-column-switch"
            control={
              <Switch
                onClick={actions.toggleColumn}
                checked={!column.hide}
                name={column.field}
                color="primary"
                sx={switchSx}
                size="small"
              />
            }
            label={column.headerName || column.field}
          />
        </Column>
      ))}

      {categorizedColumns.loads.length > 0 && (
        <>
          <Typography variant="subtitle1">{texts.columnsGroup.loads}</Typography>
          {categorizedColumns.loads.map(column => (
            <Column key={column.field}>
              <FormControlLabel
                data-testid="orders-grid-column-switch"
                data-trackid="orders-grid-column-switch"
                control={
                  <Switch
                    onClick={actions.toggleColumn}
                    checked={!column.hide}
                    name={column.field}
                    color="primary"
                    sx={switchSx}
                    size="small"
                  />
                }
                label={column.headerName || column.field}
              />
            </Column>
          ))}
        </>
      )}

      {categorizedColumns.customFields.length > 0 && (
        <>
          <Typography variant="subtitle1">{texts.columnsGroup.customFields}</Typography>
          {categorizedColumns.customFields.map(column => (
            <Column key={column.field}>
              <FormControlLabel
                data-testid="orders-grid-column-switch"
                data-trackid="orders-grid-column-switch"
                control={
                  <Switch
                    onClick={actions.toggleColumn}
                    checked={!column.hide}
                    name={column.field}
                    color="primary"
                    sx={switchSx}
                    size="small"
                  />
                }
                label={column.headerName || column.field}
              />
            </Column>
          ))}
        </>
      )}

      {categorizedColumns.notifications.length > 0 && (
        <>
          <Typography variant="subtitle1">{texts.columnsGroup.notifications}</Typography>
          {categorizedColumns.notifications.map(column => (
            <Column key={column.field}>
              <FormControlLabel
                data-testid="orders-grid-column-switch"
                data-trackid="orders-grid-column-switch"
                control={
                  <Switch
                    onClick={actions.toggleColumn}
                    checked={!column.hide}
                    name={column.field}
                    color="primary"
                    sx={switchSx}
                    size="small"
                  />
                }
                label={column.headerName || column.field}
              />
            </Column>
          ))}
        </>
      )}
    </Stack>
  )
}
