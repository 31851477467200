import type { ReactNode } from 'react'

import styled from 'styled-components'

import { VerticalLayout } from './VerticalLayout'

type Props = {
  width: number
  children?: ReactNode
  testid?: string
}

export function FormColumn(props: Props) {
  return (
    <VerticalLayout width={props.width} alignItems="center" data-testid={props.testid}>
      {props.children}
      <Separator />
    </VerticalLayout>
  )
}

const Separator = styled.span`
  position: absolute;
  top: 0;
  left: 100%;
  width: 1px;
  height: 100%;
  background: ${p => p.theme.colors.$silver};
  z-index: 4;
`

Separator.displayName = 'Separator'
