import type { PopoverOrigin, PopoverPosition } from '@mui/material'
import { Popover } from '@mui/material'

import { useIsInPopup } from '@/atoms'
import { NoDispatch } from '@/icons'
import { Text } from '@/local/components'
import { VerticalLayout } from '@/components/layout'

import { OrderStatusBadge } from '../OrderStatusBadge'

import { useOrdersStatus } from '../../hooks/useOrdersStatus'
import { useOpenWithDelay } from '../../hooks/useOpenWithDelay'

import { useFixPopupPosition } from '../../hooks/useFixPopupPosition'
import { useTexts } from './useTexts'
import { useStyles } from './hooks/useStyles'
import { RoutesDetail } from './components/RoutesDetail'
import { StatusDetail } from './components/StatusDetail'

interface Props {
  extOrderSteps: uui.domain.client.rm.ExtendedOrderStep[]
  delay?: number
  transformOrigin: PopoverOrigin
  anchorPosition: PopoverPosition
}

export function MultipleOrders(props: Props) {
  const { extOrderSteps, delay, ...popoverProps } = props

  const ordersStatus = useOrdersStatus(extOrderSteps)
  const classes = useStyles()
  const isOpen = useOpenWithDelay(delay)
  const texts = useTexts()

  const actionsRef = useFixPopupPosition()
  const isInPopup = useIsInPopup()

  const disablePortal = isInPopup || window.innerHeight == window.screen.height

  return (
    <Popover
      open={isOpen}
      action={actionsRef}
      className={classes.popover}
      // Avoid MUI moves the tooltip back to the main window through a portal
      disablePortal={disablePortal}
      anchorReference="anchorPosition"
      {...popoverProps}
    >
      <VerticalLayout
        paddingTop={16}
        paddingBottom={16}
        paddingLeft={16}
        paddingRight={ordersStatus === 'notDispatched' ? 32 : 16}
        background="$alabaster"
        className={classes.container}
      >
        {ordersStatus === 'notDispatched' && (
          <NoDispatch className={classes.noDispatchIcon} size={10} />
        )}

        <Text size="$m" weight="$semiBold">
          {texts.orders(extOrderSteps.length)}
        </Text>

        {typeof ordersStatus === 'object' ? (
          <StatusDetail status={ordersStatus} />
        ) : (
          <OrderStatusBadge status={ordersStatus} />
        )}

        <RoutesDetail extOrders={extOrderSteps} />
      </VerticalLayout>
    </Popover>
  )
}
