import styled from 'styled-components'

export const ExperimentalBackground = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: yellow;
    opacity: 0.6;
    border-radius: 6px;
    pointer-events: none;
  }
`

ExperimentalBackground.displayName = 'ExperimentalBackground'
