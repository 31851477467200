import { useState, useCallback, useEffect } from 'react'

import { useIsUnmounted } from '@/hooks'
import { copyToClipboard } from '@/utils'

import { useTexts } from './useTexts'

export const useControllerActions = (body: string) => {
  const texts = useTexts()
  const isUnmounted = useIsUnmounted()
  const [timeOutId, setTimeOutId] = useState<NodeJS.Timer | undefined>(() => undefined)
  const [copyText, setCopyText] = useState(() => texts.copyText)

  const onCopyText = useCallback(async () => {
    const copyResult = await copyToClipboard(body)
    setCopyText(texts.copyTextCopied(copyResult))

    setTimeOutId(
      setTimeout(() => {
        if (isUnmounted()) return
        setCopyText(texts.copyText)
      }, 2000),
    )
  }, [body, texts, isUnmounted])

  useEffect(() => {
    return () => {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
    }
  }, [timeOutId])

  return {
    copyText: [copyText, onCopyText] as const,
  }
}
