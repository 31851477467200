import styled from 'styled-components'
import { applyAlphaToColor } from '@/local/components'

export const ListOverlayEditCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: ${p => applyAlphaToColor(p.theme.colors.$pureWhite, 0.85)};
`

ListOverlayEditCover.displayName = 'ListOverlayEditCover'
